import { lazy, Suspense } from "react";
import React, { useEffect, useState } from "react";
import { Routes, Route, NavLink } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Loader from "./components/loader/loader";


const TermsandCondition =lazy(()=>import("./pages/Terms/Termsandcondition1"))
const Responsiblegaming =lazy(()=>import("./pages/Terms/ResponsibleGaming"))
const Login =lazy(()=>import("./pages/login/LoginClient"))

const LoadingImg = () => {
  return (
    <>
     <div className="dashLoader">
        <i className="fas fa-spinner fa-spin window__load"> </i>
      </div>
    </>
  )  
}

const NonLayout = (props) => {
  return (
    <>
      <ToastContainer /> 
      <Loader />  
      <Suspense fallback={<LoadingImg />}>
        <Routes> 
          <Route exact path ="/" element ={<Login/>}/>
          <Route exact path ="/terms-and-conditions" element ={<TermsandCondition/>}/>
          <Route exact path ="/responsible-gaming" element ={<Responsiblegaming/>}/>
        </Routes>
      </Suspense>
    </>
  );
};

export default NonLayout;