import { lazy, Suspense } from "react"
import React, { useEffect, useState } from "react";
import { Routes, Route, NavLink } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Loader from "./components/loader/loader";
import Test from "./Test";
import DarkLoader from "./components/loader/darkLoader";
import TermsUI from "./pages/Terms/TermsUI";
import Footer from "./pages/Terms/Footer";
/* NavLink */
const PopUp = lazy(() => import("./pages/popup/PopUp"));
const CasinoSlot = lazy(() => import("./components/casinoslot/CasinoSlot"));
const CricketResultOdds = lazy(() => import("./components/sport/CricketResultOdds"));
const BookmakerMarket = lazy(() => import("./components/comman/BookmakerMarket"));
const FancyMarket = lazy(() => import("./components/comman/FancyMarket"));
const LastResult = lazy(() => import("./components/comman/LastResult"));
const InPlay = lazy(() => import("./pages/inplay/InPlay"));
const Header = lazy(() => import("./includes/Header"));
const Slot = lazy(() => import("./pages/slot/Slot"));
const SuperOver = lazy(() => import("./pages/casino/SuperOver"));
const AmarAkbarAnthony = lazy(() => import("./pages/casino/AmarAkbarAnthony"));
const AndarBhar = lazy(() => import("./pages/casino/AndarBhar"));
const Baccarat = lazy(() => import("./pages/casino/Baccarat"));
const Baccarat2 = lazy(() => import("./pages/casino/Baccarat2"));
const BollywoodCasiono = lazy(() => import("./pages/casino/BollywoodCasiono"));
const Cards32A = lazy(() => import("./pages/casino/Cards32A"));
const Cards32B = lazy(() => import("./pages/casino/Cards32B"));
const CardsJudgement3 = lazy(() => import("./pages/casino/CardsJudgement3"));
const CasinoMeter = lazy(() => import("./pages/casino/CasinoMeter"));
const CasinoQueen = lazy(() => import("./pages/casino/CasinoQueen"));
const CasinoWar = lazy(() => import("./pages/casino/CasinoWar"));
const CricketMatch2020 = lazy(() => import("./pages/casino/CricketMatch2020"));
const Dragon2020Tigor2 = lazy(() => import("./pages/casino/Dragon2020Tiger2"));
const DragonTiger2020 = lazy(() => import("./pages/casino/DragonTiger2020"));
const DragonTigerLion2020 = lazy(() => import("./pages/casino/DragonTigerLion2020"));
const FiveCricket = lazy(() => import("./pages/casino/FiveCricket"));
const InstantWorli = lazy(() => import("./pages/casino/InstantWorli"));
const Lottery = lazy(() => import("./pages/casino/Lottery"));
const Lucky7A = lazy(() => import("./pages/casino/Lucky7A"));
const Lucky7B = lazy(() => import("./pages/casino/Lucky7B"));
const OneDayPoker = lazy(() => import("./pages/casino/OneDayPoker"));
const OneDayTeenpatti = lazy(() => import("./pages/casino/OneDayTeenpatti"));
const Poker2020 = lazy(() => import("./pages/casino/Poker2020"));
const Race2020 = lazy(() => import("./pages/casino/Race2020"));
const SixPlayerPocker = lazy(() => import("./pages/casino/SixPlayerPocker"));
const Teenpato2 = lazy(() => import("./pages/casino/Teenpato2"));
const Teenpatti2020 = lazy(() => import("./pages/casino/Teenpatti2020"));
const TestTeenpatti = lazy(() => import("./pages/casino/TestTeenpatti"));
const WorliMatka = lazy(() => import("./pages/casino/WorliMatka"));
const LiveCasino = lazy(() => import("./components/casino/LiveCasino"));
const Sports = lazy(() => import("./pages/sports/Sports"));
const Others = lazy(() => import("./pages/others/Others"));
const AccountStatement = lazy(() => import("./pages/accountstatement/Accountstatement"));
const BetHistory = lazy(() => import("./pages/bethistory/BetHistory"));
const CasinoReportHistory = lazy(() => import("./pages/casinoreporthistory/CasinoReportHistory"));
const ChangePassword = lazy(() => import("./pages/changepassword/ChangePassword"));
const ProfitLossReport = lazy(() => import("./pages/profitlossreport/ProfitLossReport"));
const Rules = lazy(() => import("./pages/rules/Rules1"));
const SecurityAuthVerification = lazy(() => import("./pages/securityauthverification/SecurityAuthVerification"));
const SetButtonValues = lazy(() => import("./pages/setbuttonvalues/SetButtonValues"));
const Unsetteledbet = lazy(() => import("./pages/unsetteledbet/Unsetteledbet"));
// const apiFunc = lazy(() => import("./services/api"));
const OpenTeenpatti = lazy(() => import("./pages/casino/OpenTeenpatti"));
const OneDragonTiger = lazy(() => import("./pages/casino/OneDragonTiger"));
const AndarBhar2020 = lazy(() => import("./pages/casino/AndarBhar2020"));
const AllComponent = lazy(() => import("./AllComponent"));
const DragonTigerTwo = lazy(() => import("./components/comman/DragonTigerTwo"));
const DragonTigerFive = lazy(() => import("./components/comman/DragonTigerFive"));
const SixPlayertabs = lazy(() => import("./components/comman/SixPlayerTabs"));
const Home = lazy(() => import("./pages/home/Home"));
const SuperOverResult = lazy(() => import("./components/casinoresult/SuperOverResult"));
const Race20Result = lazy(() => import("./components/casinoresult/Race20Result"));
const Queenresult = lazy(() => import("./components/casinoresult/Queenresult"));
const FiveCricketResult = lazy(() => import("./components/casinoresult/FiveCricketResult"));
const AndarBhar2020Result = lazy(() =>import("./components/casinoresult/AndarBhar2020Result"));
const CardsJudgement3Result = lazy(() =>import("./components/casinoresult/CardsJudgement3Result"));
const InstantWorliResult = lazy(() =>import("./components/casinoresult/InstantWorliResult"));
const AndarBhar2Result = lazy(() =>import("./components/casinoresult/AndarBhar2Result"));
const DragonTigerResult2020 = lazy(() =>import("./components/casinoresult/DragonTigerResult2020"));
const AllResult = lazy(() => import("./components/casinoresult/AllResult"));
const Cards32bResult = lazy(() =>import("./components/casinoresult/Cards32bResult"));
const MinMaxEvenOdd = lazy(() => import("./components/comman/MinMaxEvenOdd"));
const AmarAkbarAnthonyResult = lazy(() =>import("./components/casinoresult/AmarAkbarAnthonyResult"));
const BollywoodCasionoResult = lazy(() =>import("./components/casinoresult/BollywoodCasionoResult"));
const Baccarat2Result = lazy(() =>import("./components/casinoresult/Baccarat2Result"));
const BaccaratResult = lazy(() =>import("./components/casinoresult/BaccaratResult"));
const Lucky7bResult = lazy(() =>import("./components/casinoresult/Lucky7bResult"));
const CricketMatch2020Result = lazy(() =>import("./components/casinoresult/CricketMatch2020Result"));
const CasinoMeterResult = lazy(() =>import("./components/casinoresult/CasinoMeterResult"));
const CasinoWarResult = lazy(() =>import("./components/casinoresult/CasinoWarResult"));
const DragontigerLion2020Result = lazy(() =>import("./components/casinoresult/DragontigerLion2020Result"));
const OnedayteenpattiResult = lazy(() =>import("./components/casinoresult/OnedayteenpattiResult"));
const TestTeenPattiResult = lazy(() =>import("./components/casinoresult/TestTeenPattiResult"));
const Teenpatti2020Result = lazy(() =>import("./components/casinoresult/Teenpatti2020Result"));
const OpenTeenPattiResult = lazy(() =>import("./components/casinoresult/OpenTeenPattiResult"));
const OneDayPokerResult = lazy(() =>import("./components/casinoresult/OneDayPokerResult"));
const SixplayerpockerResult = lazy(() =>import("./components/casinoresult/SixplayerpockerResult"));
const Poker2020Result = lazy(() =>import("./components/casinoresult/Poker2020Result"));
const Cards32aResult = lazy(() =>import("./components/casinoresult/Cards32aResult"));
const OneDragonTigerResult = lazy(() =>import("./components/casinoresult/OneDragonTigerResult"));
const Lucky7aResult = lazy(() =>import("./components/casinoresult/Lucky7aResult"));
const WorlimatkaResult = lazy(() =>import("./components/casinoresult/WorlimatkaResult"));
const SportResult = lazy(() => import("./components/sport/CricketResultOdds"));
const CricketDetailsResult = lazy(() =>import("./components/sport/CricketDetailsResult"));
const InputPage = lazy(() => import("./pages/unsetteledbet/test"));
const GameDetails = lazy(() => import("./components/gameDetails"));
const IPLGameDetails = lazy(() => import("./components/ipl/gameDetails"));
const TennisMatchodds = lazy(() => import("./components/tennis/MatchOdds"));
const FootballMatchodds = lazy(() => import("./components/football/MatchOdds"));
const DT2 =lazy(()=>import("./pages/casino/Dragon2020Tiger2"))
const HorseRacing  =lazy(()=>import("./components/horseracing/horseracing"))
const GreyHoundRacing = lazy(()=>import("./components/greyhound/greyhoundracing"))
const SportsBook = lazy(()=>import("./pages/indianCasino/IndianCasino"))
const Casinoslot1=  lazy(()=>import("./pages/casinoslot/CasinoSl"))
const Deposite=  lazy(()=>import("./pages/deposite/deposite"))
const Withdrawl =lazy(()=>import("./pages/deposite/withdrawl"))
const AuraCasino =lazy(()=>import("./components/casino/AuraCasino"))
const TermsandCondition =lazy(()=>import("./pages/Terms/Termsandcondition1"))
const Responsiblegaming =lazy(()=>import("./pages/Terms/ResponsibleGaming"))

const LoadingImg = () => {
  return (
    <>
     <div className="dashLoader">
        <i className="fas fa-spinner fa-spin window__load"> </i>
      </div>
    </>
  )  
}

const Layout = (props) => {
  return (
    <>
      <ToastContainer /> 
      <Loader />  
      {/* <DarkLoader />  */}
      <Suspense fallback={<LoadingImg />}>
      <Header />  
        <Routes> 
          <Route exact path="/" element={<Home />} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/sports" element={<Sports />} />
          <Route exact path="/slot" element={<Slot />} />
          <Route exact path="/others" element={<Others />} /> 
          <Route exact path="/AccountStatement" element={<AccountStatement />} />
          <Route exact path="/BetHistory" element={<BetHistory />} />
          <Route exact path="/CasinoReportHistory" element={<CasinoReportHistory />} />
          <Route exact path="/ChangePassword" element={<ChangePassword />} />
          <Route exact path="/ProfitLossReport" element={<ProfitLossReport />} />
          <Route exact path="/Rules" element={<Rules />} />
          <Route exact path="/SecurityAuthVerification" element={<SecurityAuthVerification />} />
          <Route exact path="/SetButtonValues" element={<SetButtonValues />} />
          <Route exact path="/Unsetteledbet" element={<Unsetteledbet />} />
          <Route exact path="/InputPage" element={<InputPage />} /> 
          <Route exact path="/SuperOver" element={<SuperOver />} />
          <Route exact path="/race20" element={<Race2020 />} />
          <Route exact path="/CasinoQueen" element={<CasinoQueen />} />
          <Route exact path="/FiveCricket" element={<FiveCricket />} />
          <Route exact path="/AndarBhar" element={<AndarBhar />} />
          <Route exact path="/DragonTiger2020" element={<DragonTiger2020 />} />
          <Route exact path="/Baccarat2" element={<Baccarat2 />} />
          <Route exact path="/Baccarat" element={<Baccarat />} />
          <Route exact path="/Lucky7A" element={<Lucky7A />} />
          <Route exact path="/Lucky7B" element={<Lucky7B />} />
          <Route exact path="/dt2" element={<DT2 />} />
          <Route exact path="/Teenpatti2020" element={<Teenpatti2020 />} />
          <Route exact path="/AmarAkbarAnthony" element={<AmarAkbarAnthony />} />
          <Route exact path="/BollywoodCasiono" element={<BollywoodCasiono />} />
          <Route exact path="/Cards32A" element={<Cards32A />} />
          <Route exact path="/Cards32B" element={<Cards32B />} />
          <Route exact path="/CardsJudgement3" element={<CardsJudgement3 />} />
          <Route exact path="/CasinoMeter" element={<CasinoMeter />} />
          <Route exact path="/CasinoWar" element={<CasinoWar />} />
          <Route exact path="/CricketMatch2020" element={<CricketMatch2020 />} />
          <Route exact path="/Dragon2020Tigor2" element={<Dragon2020Tigor2 />} />
          <Route exact path="/DragonTigerLion2020" element={<DragonTigerLion2020 />} />
          <Route exact path="/InstantWorli" element={<InstantWorli />} />
          <Route exact path="/Lottery" element={<Lottery />} />
          <Route exact path="/OneDayPoker" element={<OneDayPoker />} />
          <Route exact path="/OneDayTeenpatti" element={<OneDayTeenpatti />} />
          <Route exact path="/Poker2020" element={<Poker2020 />} />
          <Route exact path="/SixPlayerPocker" element={<SixPlayerPocker />} />
          <Route exact path="/Teenpato2" element={<Teenpato2 />} />
          <Route exact path="/TestTeenpatti" element={<TestTeenpatti />} />
          <Route exact path="/WorliMatka" element={<WorliMatka />} />
          <Route exact path="/WorliMatka" element={<WorliMatka />} />
          <Route exact path="/OpenTeenpatti" element={<OpenTeenpatti />} />
          <Route exact path="/OneDragonTiger" element={<OneDragonTiger />} />
          <Route exact path="/AndarBhar2020" element={<AndarBhar2020 />} />
          <Route exact path="/DragonTigerTwo" element={<DragonTigerTwo />} />
          <Route exact path="/DragonTigerFive" element={<DragonTigerFive />} />
          <Route exact path="/DragonTigerFive" element={<SixPlayertabs />} />
          <Route exact path="/SuperOverResult" element={<SuperOverResult />} />
          <Route exact path="/Race20Result" element={<Race20Result />} />
          <Route exact path="/Queenresult" element={<Queenresult />} />
          <Route exact path="/FiveCricketResult" element={<FiveCricketResult />} />
          <Route exact path="/AndarBhar2020Result" element={<AndarBhar2020Result />} />
          <Route exact path="/CardsJudgement3Result" element={<CardsJudgement3Result />} />
          <Route exact path="/InstantWorliResult" element={<InstantWorliResult />} />
          <Route exact path="/AndarBhar2Result" element={<AndarBhar2Result />} />
          <Route exact path="/DragonTigerResult2020" element={<DragonTigerResult2020 />} />
          <Route exact path="/Cards32bResult" element={<Cards32bResult />} />
          <Route exact path="/MinMaxEvenOdd" element={<MinMaxEvenOdd />} />
          <Route exact path="/AmarAkbarAnthonyResult" element={<AmarAkbarAnthonyResult />} />
          <Route exact path="/BollywoodCasionoResult" element={<BollywoodCasionoResult />} />
          <Route exact path="/Baccarat2Result" element={<Baccarat2Result />} />
          <Route exact path="/BaccaratResult" element={<BaccaratResult />} />
          <Route exact path="/Lucky7bResult" element={<Lucky7bResult />} />
          <Route exact path="/CricketMatch2020Result" element={<CricketMatch2020Result />} />
          <Route exact path="/CasinoMeterResult" element={<CasinoMeterResult />} />
          <Route exact path="/CasinoWarResult" element={<CasinoWarResult />} />
          <Route exact path="/DragontigerLion2020Result" element={<DragontigerLion2020Result />} />
          <Route exact path="/OnedayteenpattiResult" element={<OnedayteenpattiResult />} />
          <Route exact path="/TestTeenPattiResult" element={<TestTeenPattiResult />} />
          <Route exact path="/Teenpatti2020Result" element={<Teenpatti2020Result />} />
          <Route exact path="/OpenTeenPattiResult" element={<OpenTeenPattiResult />} />
          <Route exact path="/OneDayPokerResult" element={<OneDayPokerResult />} />
          <Route exact path="/SixplayerpockerResult" element={<SixplayerpockerResult />} />
          <Route exact path="/Poker2020Result" element={<Poker2020Result />} />
          <Route exact path="/Cards32aResult" element={<Cards32aResult />} />
          <Route exact path="/OneDragonTigerResult" element={<OneDragonTigerResult />} />
          <Route exact path="/Lucky7aResult" element={<Lucky7aResult />} />
          <Route exact path="/WorlimatkaResult" element={<WorlimatkaResult />} />
          <Route exact path="/SportResult" element={<SportResult />} />
          <Route exact path="/CricketDetailsResult" element={<CricketDetailsResult />} /> 
          <Route exact path="/AllComponent" element={<AllComponent />} />
          <Route exact path="/AllResult" element={<AllResult />} /> 
          <Route exact path="/GameDetails/:Matchid/:eventIds/:marketIds" element={<GameDetails />} /> 
          <Route exact path="/GameDetails/:Matchid/:eventIds/:marketIds" element={<IPLGameDetails />} /> 
          <Route exact path="/test" element={<Test />} /> 
          <Route exact path="/tennis/:Matchid/:eventIds/:marketIds" element={<TennisMatchodds />}/>
          <Route exact path ="/Soccer/:Matchid/:eventIds/:marketIds" element={<FootballMatchodds/>} />
          <Route exact path ="/horseracing" element ={<HorseRacing/>}/>
          <Route exact path ="/greyhoundracing" element ={<GreyHoundRacing/>}/>
          <Route exact path ="/indiancasino" element ={<SportsBook/>}/>
          <Route exact path ="/casinoslot" element ={<Casinoslot1/>}/>
          <Route exact path ="/deposite" element ={<Deposite/>}/>
          <Route exact path ="/withdrawl" element ={<Withdrawl/>}/>
          <Route exact path ="/indian-poker-view/:userAuraToken/:operatorId/:tableId" element={<AuraCasino/>}/>
          {/* <Route exact path ="/terms-and-conditions" element ={<TermsandCondition/>}/> */}
          <Route exact path ="/terms-and-conditions" element ={<TermsandCondition/>}/>
          <Route exact path ="/responsible-gaming" element ={<Responsiblegaming/>}/>
        </Routes>
        <TermsUI/>
        <Footer/>
      </Suspense>
     
    </>
  );
};

export default Layout;