import React from 'react'

const Loader = () => {
  return (
    <>
      <section className="loaderWrap">
        {/* <img src="/images/logo.png" className="logo" />  */}
        <div className="spiner"> 
          <i className="fas fa-spinner fa-spin"> </i> 
        </div>
      </section>
    </>
  )
}

export default Loader