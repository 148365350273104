import react, { useEffect, useReducer, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
/*  COMMAN CSS USED  */
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import "./assets/scss/global.scss";
import "./assets/scss/style.scss";
/*   */
import Layout from "./Layout";
import LoginClient from "./pages/login/LoginClient";
import NonLayout from "./Nonlayout";
import { UserContext, UserProvider } from "./context/LoginContext";

import "react-toastify/dist/ReactToastify.css";

function App() {

  return (
    <>
      <UserProvider>
        <UserContext.Consumer>
          {({isLogin}) => (
            isLogin ? <Layout/> : <NonLayout/>
          )}
        </UserContext.Consumer>
      </UserProvider>
    
    </>
  );
}

export default App;
